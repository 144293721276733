import React from "react"


const TestPage = () => {
  const siteUrl = "https://www.oooooo.com"
  const pageTitle = "Título de prueba"
  const pageDescription = "Descripción de prueba"
  const imageUrl = `${siteUrl}/ruta-a-tu-imagen.jpg` // Asegúrate de que esta imagen existe

  return (
    <>
    
    </>
  )
}

export default TestPage
